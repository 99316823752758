import { useFlags } from 'launchdarkly-react-client-sdk'

import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { isTokenPrefixBearer } from 'api/protocols'

import { FetchCachedThunkProps } from 'types'

import useAppDispatch from './useAppDispatch'

import { FeatureFlags, isFeatureFlagOn } from 'components/FeatureFlag'

import { fetchGoalStatesForEmployeeOnDate, fetchGoalsOnDateThunk } from 'slices/goals'
import { getDefaultSelectedStore } from 'slices/storeSwitcher'
import { getWhoami } from 'slices/whoami'

interface GetGoalsOnDateArgs extends FetchCachedThunkProps {
  date: string
}

export const useGetGoalsOnDate = () => {
  const dispatch = useAppDispatch()
  const flags = useFlags()

  const whoami = useSelector(getWhoami)
  const selectedStore = useSelector(getDefaultSelectedStore)

  const shouldFetchGoalServiceGoals = isFeatureFlagOn(
    flags,
    FeatureFlags.displayGoalsSelfServiceTmx
  )

  const useFirebaseLoginTmx = isFeatureFlagOn(flags, FeatureFlags.useFirebaseLoginTmx)

  const getGoalsOnDate = useCallback(
    async ({ date, getCachedResults = false }: GetGoalsOnDateArgs) => {
      if (!whoami) return

      await dispatch(fetchGoalsOnDateThunk({ getCachedResults, date }))

      if (shouldFetchGoalServiceGoals) {
        await dispatch(
          fetchGoalStatesForEmployeeOnDate({
            date,
            userProfileId: whoami.user_profile_id,
            storeGroupId: selectedStore.group_id,
            shouldUseFirebase: useFirebaseLoginTmx && isTokenPrefixBearer(),
            locationId: selectedStore.id
          })
        )
      }
    },
    [whoami, selectedStore, shouldFetchGoalServiceGoals]
  )

  return { getGoalsOnDate, shouldFetchGoalServiceGoals }
}
