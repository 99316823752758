export const REDEMPTION = 'redemption'
export const ARRIVE_ON_TIME = 'checkin'
export const WORK_A_SHIFT = 'work_a_shift'
export const WORK_A_TIME_BASED_SHIFT = 'work_a_time_based_shift'
export const COMPARISON = 'comparison'
export const LESS = 'less'
export const GREATER = 'greater'
export const GREATER_MULTIPLE = 'greater_multiple'
export const QUESTION = 'question'
export const PROMPTED_QUESTION = 'prompted_question'
export const API = 'api'
export const CSV = 'csv'
export const MANUAL_ENTRY = 'Manual Entry'
export const COLUMN_FIELD = 'Column Field'
export const COLUMN_VALUE = 'Column Value'
export const DATE = 'date'
export const TIME = 'time'
export const INTEGER = 'integer'
export const DECIMAL = 'decimal'
export const FILTER = 'filter'
export const SELECTOR = 'selector'

export const pointTypeList = [
  ARRIVE_ON_TIME,
  LESS,
  GREATER,
  QUESTION,
  PROMPTED_QUESTION,
  WORK_A_SHIFT
]
export const dataSourceList = [API, CSV, MANUAL_ENTRY]
export const thresholdList = [COLUMN_FIELD, COLUMN_VALUE]
export const csvSchemaColumnFieldList = [DATE, TIME, INTEGER, DECIMAL]
export const csvSchemaFilterSelector = [FILTER, SELECTOR]

export const SB_CATEGORIES = {
  icons: 'Icons/',
  categoryIcons: 'Icons/Category Icons/',
  goalIcons: 'Icons/Goal Icons/'
}
