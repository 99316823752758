import { styled, Typography, TypographyTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { StyledComponent } from '@mui/styles'

import { ElementType, HTMLAttributes } from 'react'

import RowItem, { RowItemProps } from 'components/rowItem'

import { Colors } from '../../theme'
import { GoalItemSlice, GoalItemStyleProps } from './goalsItem'

export const StyledGoalsItem = styled(RowItem, {
  shouldForwardProp: (prop) => prop !== 'selected'
})<GoalItemStyleProps>(({ item: { completed }, theme }) => ({
  padding: 0,
  cursor: 'pointer',
  '.RowItem__wrapper': {
    padding: theme.spacing(3)
  },
  '.RowItem__extraInfo': {
    position: 'relative',
    flexShrink: 0,
    backgroundColor: completed ? Colors.successLight : Colors.gray200,
    height: '36px'
  }
})) as StyledComponent<RowItemProps<GoalItemSlice> & GoalItemStyleProps>

export const StyledGoalsItemWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
})

export const StyledGoalsItemTitle = styled(Typography)({
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '150%'
})

export const StyledGoalsItemDescription = styled(Typography)(() => ({
  fontWeight: 'normal',
  color: Colors.gray500
}))

export const StyledGoalsItemIconWrapper = styled('div')({
  position: 'absolute',
  top: '-8px',
  right: '-8px',
  display: 'flex'
})

export const StyledGoalsItemPointsValue = styled<
  OverridableComponent<
    TypographyTypeMap<{ component: ElementType<HTMLAttributes<HTMLElement>> }>
  >
>(Typography, {
  shouldForwardProp: (prop) => prop !== 'item'
})<GoalItemStyleProps>(({ item: { result_status } }) => ({
  fontWeight: '600',
  lineHeight: '120%',
  color: result_status === 'not_achieved' ? Colors.gray400 : Colors.black
}))

export const StyledGoalsItemFooter = styled('div', {
  shouldForwardProp: (prop) => prop !== 'item'
})<GoalItemStyleProps>(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: Colors.white,
  borderTop: `1px solid ${Colors.inactive}`
}))

export const StyledGoalsItemResult = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'item'
})<GoalItemStyleProps>(() => ({
  fontWeight: 'normal',
  color: Colors.black,
  textTransform: 'none'
}))
