import { isArray, isEmpty } from 'lodash'

import { Key } from 'react'

import { ImpersonationArgs, ObtainAuthBody, AuthedFetchDTO, AuthedUpsertDTO } from 'types'

import { APIVersion, getEnvAPIHost, getRMSApiHost } from 'utils/helpers'
import { logError } from 'utils/logger'

import ENDPOINTS from './endpoints'

let DEFAULT_HEADERS = {
  'Content-Type': 'application/json'
}
const DEFAULT_PREFIX = 'api'
const BEARER = 'Bearer'

export const tokenPrefix = (): string => localStorage?.getItem('prefix') || 'JWT'

export const isTokenPrefixBearer = (): boolean =>
  tokenPrefix().toLowerCase() === BEARER.toLowerCase()

export const updateHeaders = (entry: Record<string, string>) => {
  DEFAULT_HEADERS = {
    ...entry,
    'Content-Type': 'application/json'
  }
}

export class HttpError extends Error {
  status: number
  static message: string
  constructor(message: string, status: number) {
    super(message)
    this.name = 'HttpError'
    this.message = message
    this.status = status
  }
}

interface FetchOptions {
  body?: ReadableStream<Uint8Array> | string
  headers?: Record<string, string>
  method?: 'POST' | 'PUT' | 'GET' | 'PATCH'
}

type formatBodyProps = Record<string, string | string[]>

export const formatBody = (body: formatBodyProps): string => {
  if (isEmpty(body)) return ''
  // Stringify nested array children
  const formattedBody = Object.keys(body).reduce((memo, key) => {
    let value = body[key]
    if (isArray(value)) {
      value = value.join('\n')
    }
    return { ...memo, [key]: value }
  }, {})
  // Make sure we capture the full body in our redux state
  return JSON.stringify(formattedBody)
}

export const handleErrors = async (result: Response) => {
  if (result && !result.ok) {
    const rawBody = await result.json()
    const body = formatBody(rawBody)
    const error = new HttpError(body || result.statusText, result.status)
    logError(`Oh no! Looks like there was an error fetching: ${error?.message}`, {
      error,
      result,
      body
    })
    throw error
  }
}

export const handleFetch = async (url: string, options: FetchOptions = {}) => {
  const urlWithApiHost = `${getEnvAPIHost()}/api/${url}`
  try {
    const result = await fetch(urlWithApiHost, options)
    await handleErrors(result)
    return await result.json()
  } catch (error) {
    /*
     * If we hit this, we either have a network error
     * or we have re-thrown an error from handleErrors
     * TODO: Figure out how to saturate DD error tracking with sourcemaps
     */
    if (!(error instanceof HttpError)) {
      // Only log if it is not our custom thrown exception
      logError(
        `Oh no! Looks like there was a network error: ${(error as Error)?.message}`,
        {
          error,
          url: urlWithApiHost
        }
      )
    }
    throw error
  }
}

export const _get = async <ReturnT, ParamsT>(
  url_path: string,
  authedDTO: AuthedFetchDTO<ParamsT>,
  apiVersion: keyof typeof APIVersion
): Promise<ReturnT> => {
  const { access } = authedDTO
  const query_params = authedDTO?.queryParams as Record<Key, string> | undefined
  const param_str = query_params
    ? `?${Object.keys(query_params)
        .map((key) => key + '=' + query_params[key])
        .join('&')}`
    : ''
  return await handleFetch(`${apiVersion}/${url_path}${param_str}`, {
    headers: {
      Authorization: `${tokenPrefix()} ${access}`
    }
  })
}

export const _post_auth = async (
  url_path: string,
  body: ObtainAuthBody | ImpersonationArgs
) => {
  return await handleFetch(url_path, {
    method: 'POST',
    headers: DEFAULT_HEADERS,
    body: JSON.stringify(body)
  })
}

export const _public_post = async (
  body: unknown,
  path: (typeof ENDPOINTS)[keyof typeof ENDPOINTS],
  apiVersion?: keyof typeof APIVersion,
  prefix: string | null = DEFAULT_PREFIX
) => {
  const versionPath = apiVersion ? `${apiVersion}/${path}` : path
  const prefixedPath = prefix ? `${prefix}/${versionPath}` : versionPath
  const url_path = `${getEnvAPIHost()}/${prefixedPath}`
  return await fetch(url_path, {
    method: 'POST',
    headers: DEFAULT_HEADERS,
    body: JSON.stringify(body)
  })
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const _post = async <ReturnT, DataT>(
  url_path: string,
  authedDTO: AuthedUpsertDTO<DataT>,
  apiVersion: keyof typeof APIVersion
): Promise<ReturnT> => {
  const { access, data } = authedDTO
  return await handleFetch(`${apiVersion}/${url_path}`, {
    method: 'POST',
    headers: {
      ...DEFAULT_HEADERS,
      Authorization: `${tokenPrefix()} ${access}`
    },
    body: JSON.stringify(data)
  })
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const _put = async <ReturnT, DataT>(
  url_path: string,
  authedDTO: AuthedUpsertDTO<DataT>,
  apiVersion: keyof typeof APIVersion
): Promise<ReturnT> => {
  const { access, data } = authedDTO
  return await handleFetch(`${apiVersion}/${url_path}`, {
    method: 'PUT',
    headers: {
      ...DEFAULT_HEADERS,
      Authorization: `${tokenPrefix()} ${access}`
    },
    body: JSON.stringify(data)
  })
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const _patch = async <ReturnT, DataT>(
  url_path: string,
  authedDTO: AuthedUpsertDTO<DataT>,
  apiVersion: keyof typeof APIVersion
): Promise<ReturnT> => {
  const { access, data } = authedDTO
  return await handleFetch(`${apiVersion}/${url_path}`, {
    method: 'PATCH',
    headers: {
      ...DEFAULT_HEADERS,
      Authorization: `${tokenPrefix()} ${access}`
    },
    body: JSON.stringify(data)
  })
}

export const _rms_put = async (path: string, token: string, body?: unknown) => {
  const url_path = `${getRMSApiHost()}/api/${path}`

  return await fetch(url_path, {
    method: 'PUT',
    body: !body ? null : JSON.stringify(body),
    headers: {
      ...DEFAULT_HEADERS,
      Authorization: `Bearer ${token}`
    }
  })
}
