import { useFlags } from 'launchdarkly-react-client-sdk'

import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { sendOTPV2 } from 'api'

import { LoginStateParams, LoginWithTypePathParams } from 'types'

import EmailInput from 'pages/login/components/emailInput'
import { LoginType, OTPChannel } from 'pages/login/constants'

import { ManagerSupport, PhoneNumberInput } from '../../components'
import { FeatureFlag, FeatureFlags, isFeatureFlagOn } from 'components/FeatureFlag'

import { useIsOnSmallDevice } from 'hooks/mediaQueries'
import { useNavigation } from 'hooks/useNavigation'

import { getLoginWithTypePath, RootPaths } from 'utils/helpers'

import {
  StyledError,
  StyledForm,
  StyledLink,
  StyledSignInText,
  StyledText,
  StyledWelcomeText
} from './enabled.styles'

import { cleanPhoneNumber, isValidPhoneNumber } from '../../utils'
import LoginFormLayout from '../LoginFormLayout'
import { useFirebase } from '../otp/useFirebase'

export const EnabledLoginForm = () => {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const isOnSmallDevice = useIsOnSmallDevice()
  const [loginError, setLoginError] = useState<string | null>('')
  const flags = useFlags()
  const useFirebaseLoginTmx = isFeatureFlagOn(flags, FeatureFlags.useFirebaseLoginTmx)

  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const { type: loginType } = useParams<LoginWithTypePathParams>()

  const shouldShowError = Boolean(loginError && (phoneNumber || email))
  const smallDeviceTitleTextSize = isOnSmallDevice ? 'h4' : 'h3'
  const smallDeviceErrorTextSize = isOnSmallDevice ? 'h5' : 'body1'

  const isEmail = loginType === LoginType.email

  const { initializeReCaptchaVerifier, reCaptchaToken, submitLoginForm } = useFirebase()

  const resetLoginError = () => {
    setLoginError(null)
  }

  useEffect(() => {
    if (useFirebaseLoginTmx && !isEmail) {
      initializeReCaptchaVerifier()
    }
  }, [useFirebaseLoginTmx, isEmail])

  useEffect(() => {
    if (reCaptchaToken && (phoneNumber || email)) {
      resetLoginError()
    }
  }, [reCaptchaToken])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (loginType === LoginType.phone && !phoneNumber) {
      return
    }
    if (isEmail && !email) {
      return
    }

    const channel = OTPChannel[loginType]
    const loginValue = isEmail ? email.toLowerCase() : phoneNumber

    if (!useFirebaseLoginTmx || isEmail) {
      const res = await sendOTPV2({ channel, to: loginValue })
      if (!res.ok) {
        setLoginError(`pages.login.form.errors.${loginType ?? 'phone'}NotFoundError`)
      } else {
        const state: LoginStateParams = {
          loginValue: loginValue,
          loginType,
          verificationId: ''
        }
        navigation.replace({
          pathname: RootPaths.verifyOTP,
          state
        })
      }
    } else if (useFirebaseLoginTmx && reCaptchaToken) {
      await submitLoginForm(phoneNumber, email, setLoginError, loginType, loginValue)
    } else if (useFirebaseLoginTmx && !reCaptchaToken) {
      setLoginError('pages.login.form.errors.missingRecaptcha')
    }
  }

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event
    const cleanedPhoneNumber = cleanPhoneNumber(value)
    setPhoneNumber(cleanedPhoneNumber)
    if ((loginError && isValidPhoneNumber(cleanedPhoneNumber)) || !phoneNumber) {
      resetLoginError()
    }
    if (cleanedPhoneNumber.length > 10) {
      setLoginError('pages.login.form.errors.mobileNumberInvalidError')
    }
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event
    if ((loginError && email) || !email) {
      resetLoginError()
    }
    // TODO: Perform email validation here
    setEmail(value)
  }

  const renderInput = () => {
    switch (loginType) {
      case LoginType.email:
        return (
          <EmailInput
            value={email}
            loginError={loginError}
            shouldShowError={shouldShowError}
            handleChange={handleEmailChange}
          />
        )
      case LoginType.phone:
      default:
        return (
          <PhoneNumberInput
            showNextButton
            value={phoneNumber}
            loginError={loginError}
            shouldShowError={shouldShowError}
            handleChange={handlePhoneChange}
          />
        )
    }
  }

  const handleGoBack = () => {
    navigation.replace({
      pathname: RootPaths.welcome,
      preserveParams: false
    })
  }

  const signInTitle = isEmail
    ? 'pages.login.form.signInTitleEmail'
    : 'pages.login.form.signInTitlePhone'

  return (
    <LoginFormLayout handleGoBack={handleGoBack}>
      <StyledForm
        data-testid="EnabledLoginForm"
        isOnSmallDevice={isOnSmallDevice}
        onSubmit={handleSubmit}
      >
        <>
          <StyledWelcomeText
            variant={smallDeviceTitleTextSize}
            isOnSmallDevice={isOnSmallDevice}
            data-testid="WelcomeText"
          >
            {t('pages.login.form.enabled.welcomeTitle')}
          </StyledWelcomeText>
          <StyledSignInText
            variant={smallDeviceTitleTextSize}
            isOnSmallDevice={isOnSmallDevice}
            data-testid="SignInTitle"
          >
            {t(signInTitle)}
          </StyledSignInText>
          <StyledText
            variant={isOnSmallDevice ? 'subtitle2' : 'subtitle1'}
            data-testid="SendCodeSubTitle"
          >
            {t('pages.login.form.enabled.sendCodeSubtitle')}
          </StyledText>
        </>
        {renderInput()}
        {shouldShowError && (
          <StyledError
            isOnSmallDevice={isOnSmallDevice}
            data-testid="ErrorText"
            variant={smallDeviceErrorTextSize}
          >
            <Trans
              t={t}
              i18nKey={loginError!}
              components={{
                PhoneLink: (
                  <StyledLink
                    to={getLoginWithTypePath('phone')}
                    onClick={resetLoginError}
                  />
                )
              }}
            />
          </StyledError>
        )}
        {shouldShowError &&
          loginError === `pages.login.form.errors.${loginType}NotFoundError` && (
            <ManagerSupport />
          )}
      </StyledForm>
      <FeatureFlag flag={FeatureFlags.useFirebaseLoginTmx}>
        <div id="recaptcha-container"></div>
      </FeatureFlag>
    </LoginFormLayout>
  )
}
