import { graphql } from 'generated'
import { GetGoalStatesForEmployeeOnDateQuery } from 'generated/graphql'

import { TypedDocumentNode } from '@graphql-typed-document-node/core'

import { _client } from 'api/middleware'

interface GetGoalsStatesForEmployeeOnDateProps {
  storeGroupId: string
  userProfileId: string
  date: string
  shouldUseFirebase?: boolean
  locationId?: string
}

export const getGoalStatesForEmployeeOnDate = async ({
  storeGroupId,
  userProfileId,
  date,
  shouldUseFirebase,
  locationId
}: GetGoalsStatesForEmployeeOnDateProps): Promise<GetGoalStatesForEmployeeOnDateQuery> => {
  const document = graphql(`
    query GetGoalStatesForEmployeeOnDate(
      $storeGroupId: UUID!
      $userProfileId: UUID!
      $date: Date!
      $locationId: UUID
    ) {
      getGoalStatesForEmployeeOnDate(
        date: $date
        userProfileId: $userProfileId
        storeGroupId: $storeGroupId
        locationId: $locationId
      ) {
        goalDescription
        goalId
        goalName
        goalPoints
        goalType
        kaiserGoalId
        state
        minimum
        maximum
        value
        units
      }
    }
  `) as TypedDocumentNode<
    GetGoalStatesForEmployeeOnDateQuery,
    GetGoalsStatesForEmployeeOnDateProps
  >

  const variables = { storeGroupId, userProfileId, date, locationId }
  return _client({ shouldUseFirebase }).request(document, variables)
}
